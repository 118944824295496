.previous-questions {
    .button {
        font-size: 16px;
        background-color: $bg-grey;
        display: flex;
        justify-content: center;
        align-items: center;

        &.primary {
            background: $primary;
            color: $white;
        }

        &:hover {
            background: darken($bg-grey, 15%);
            color: $paragraph;
        }
    }
}
