.modal-description {
    display: flex;
    z-index:11;
    width: 100%;
    height: 100vh;
    background-color: #000000bb;
    position: fixed;
    overflow-x: hidden;
    top: -100vh;
    transform: translateY(-100vh);
    left: 0px;
    transition: .4s;
    

    .modal-dialog {
        max-width: 95%;
        max-height: 95vh;
        margin: auto;

        @include sm {
            max-width: 80%;
        }
    }

    &.visible-modal {
        transform: translateY(100vh);
        transition: .4s;
    }

    .modal-content {
        padding: 10px;

        @include sm {
            padding: 30px;
        }
    }

    .modal-body {
        min-height: 120px;
        max-height: 70vh;
        overflow-y: auto;
    }

    .modal-footer {
        border-top: none;
        padding-top: 15px;
        justify-content: center;

        .button {
            width: 100%;
        }

        @include sm {
            justify-content: flex-end;

            .button {
                width: auto;
            }
        }
    }
}
