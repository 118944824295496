.questionaire-arrow {
    position: absolute;
    top: 50px;
    display: block;
    
    .arrow {
        width: 112px;
        height: 110px;
        margin-bottom: 20px;
    }

    .arrow-text {
        display: block;
        text-align: center;
        width: 70%;
        line-height: 35px;
        font-size: 22px;
        font-family: $mediumitalic;
    }
}

.questionaire-status {
    * {
        margin-top: 20px;
        font-family: $bold !important;
        font-size: 20px !important;
        line-height: 20px;
        color: $paragraph;

        a {
            color: $primary;
        }
    }
}