section.dashboard {
    margin: 0 32px 90px 32px;

    @include lg {
        margin: 0 0 90px 0;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .title-block {
        padding-top: 34px;
        margin-bottom: 9px;
        font-family: $bold;
        font-size: 25px;
        text-align: center;

        @include lg {
            text-align: left;
        }
    }

    .content-header {
        padding: 25px;
        font-family: $light;
        font-size: 16px;
        user-select: none;

        .table-headers {
            font-size: 20px;
        }
    }

    .error-delete {
        color: $error;
    }

    .content-footer {
        padding: 15px 25px 30px 25px;
        font-family: $light;
        font-size: 16px;
        display: none;

        @include lg {
            display: block;
        }
    }
}