.login {
    margin-bottom: 120px;

    @include md {
        margin-bottom: 175px;
    }

    .col-login {
        background-color: $input-bg-active;
        padding: 65px 40px 50px 40px;
        max-width: 700px;

        @include md {
            padding: 75px 90px 50px 90px;
        }

        .input {
            background-color: $white;
        }

        .container-button {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include sm {
                    width: auto;
                    justify-content: center;
                }
            }
        }

        .container-actions {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            font-size: 18px;
            color: $input-label;

            span:first-child{
                margin-bottom: 20px;

                @include md {
                    margin-bottom: 0px;
                }
            }

            a {
                text-decoration: underline;
                color: $input-label;

                &.login-forgot {
                    text-decoration: none;
                }
            }

            @include md {
                margin-top: 80px;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    .wait-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            font-size: 26px !important;
        }
    }
}