.validate-page {
    h2 {
        text-align: center;
    }

    .container-laoder {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 30vh;

        h4 {
            font-family: $semibold;
            margin-top: 80px;
            min-height: 80px;

            &.error {
                color: $error;
            }
        }

        .container-bottom-validate {
            min-height: 100px;

            .btn-loader, .button {
                margin-bottom: 80px;
            }
        }
    }
}