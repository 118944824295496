.button {
    display: table;
    padding: 12px 31px;
    border-radius: 50px;
    text-decoration: none;
    transition: .2s;
    text-align: center;
    font-family: $bold;
    font-size: 18px;
    letter-spacing: 0.02em;

    &:focus,
    &:active {
        outline: 0;
        border: 0;
    }

    &.fullwidth {
        width: 100%;
    }

    &.primary {
        background: $secondary;
        color: $white;
    }

    &.secondary {
        background: $primary;
        color: $white;

        &:hover {
            cursor: pointer;
            background: darken($primary, 5%);
            transition: .2s;
            color: $white;
        }
    }

    &.green {
        background: $success;
        color: $heading;

        &:hover {
            cursor: pointer;
            background: darken($success, 20%);
            transition: .2s;
            color: $heading;
        }

        .arrow {
            filter: brightness(0.1);
        }
    }

    &:hover {
        cursor: pointer;
        background: darken($secondary, 12%);
        transition: .2s;
        color: $white;
    }

    &.disabled {
        cursor: auto !important;
        filter: opacity(45%);
        background: $bg-grey !important;
        color: $paragraph;

        &:hover {
            color: $paragraph;
        }

        .arrow {
            filter: grayscale(100%); 
        }
    }

    &.button-form {
        border: none;
    }

    .arrow {
        height: 15px;
        width: auto;
        margin-left: 7px;
    }

    .plus {
        height: 20px;
        margin-left: 10px;
        transform: translateY(-1px);
    }

    .arrow-reverse {
        transform: rotate(180deg);
        height: 15px;
        width: auto;
        margin-right: 7px;
    }
}