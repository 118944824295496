.request-footer {
    margin: 30px 0 130px 0;
    user-select: none;

    .second-btn {
        margin-top: 17px;

        @include lg {
            margin-top: 0;
        }
    }

    .btn-container {
        width: 100%;

        @include lg {
            width: auto;
            display: inline-block;
        }

        .button {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include lg {
                justify-content: center;
            }
        }
    }

    .error-questionaire {
        margin-right: 45px;
        font-size: 20px;
        color: $primary;
        font-family: $medium;
    }
}
