footer {
    background: $bg-grey;
    width: 100%;
    padding: 64px 32px 32px 32px;

    @include lg {
        padding: 64px 0px 32px 0px;
    }

    h4{
        color: $paragraph;
        font-family: $bold!important;
        font-size: 25px;
        margin-bottom: 30px;
    }

    p{
        color: $paragraph;
        font-family: $light!important;
        font-size: 16px;
        color: $black;
        letter-spacing: 0.06em;
        max-width: 450px;
    }

    .row-sup{
        & > div{
            margin-bottom: 40px;

            @include lg{
                margin-bottom: 0px;
            }
        }
    }

    .row-bottom{
        margin-top: 0;

        @include lg{
            margin-top: 80px;
        }

        .col-rrss{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            transition: 0.2s;

            img {
                height: 28px;
                width: 28px;
            }

            a:hover{
                opacity: 0.85;
                transition: 0.2s;
            }

            @include sm{
                justify-content: flex-end;
                margin-bottom: 0
            }
        }
    }

    .col-payments {
        gap: 10px;

        img {
            max-height: 15px;
        }
    }

    .CookieConsent {
        font-family: $medium !important;
        font-size: 14px !important;
        background: $bg-grey !important;
        color: $paragraph !important;
        padding: 0px;
        flex-direction: column;
        align-items: center !important;

        @include lg {
            flex-direction: row;
            padding: 0px 50px;
        }

        div {
            color: $black !important;
            font-size: 14px !important;
            text-align: center !important;

            &:nth-child(1) {
                flex: auto !important;
                margin-bottom: 0px !important;

                @include lg {
                    text-align: left !important;
                    margin-bottom: 15px !important;
                }
            }
        }

        a {
            text-decoration: underline !important;
            font-size: 14px !important;
        }

        button {
            background: $primary !important;
            color: $white !important;
            padding: 6px 14px !important;
            border-radius: 6px;
            margin-top: 5px !important;

            @include lg {
                margin-top: 15px !important;
            }

            &:hover {
                background: $paragraph !important;
            }
        }
    }
}