.billing {
    .container-copy {
        .button {
            width: 100%;

            @include lg {
                width: auto;
            }
        }
    }

    .country-selector {
        width: 100%;
    }

    .promotion {
        p {
            line-height: 1em;
            margin-bottom: 0.6rem;
        }
    }

    .total-cost {
        .subtotal,
        .discount,
        .total {
            font-family: $semibold !important;
            font-size: 1rem !important;
            text-align: end;
            margin-bottom: 0;
        }

        .total {
            font-family: $bold !important;
            font-size: 1.4rem !important;
        }
    }

    .container-submit {
        .button {
            width: 100%;

            @include lg {
                width: auto;
            }

            &.disabled {
                background-color: $input-label !important;
                cursor: inherit;
            }
        }
    }
}
