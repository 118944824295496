section.fourofour {
    max-width: 90%;
    margin: auto;

    .container-fourofour {
        min-height: 300px;
        
        .number {
            font-size: 8em;
            line-height: 1em;
        }

        .error {
            font-size: 2em;
            line-height: 1em;
            margin-bottom: 60px;
        }

        .container-buttons {
            margin-bottom: 140px;

            .button {
                width: fit-content;
                min-height: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}