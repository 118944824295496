.contact-form {
    margin-bottom: 60px;

    @include md {
        margin-bottom: 270px;
    }

    .col-info {
        position: relative;

        @include md {
            padding: 0 8px 0 0 !important;
        }

        .container-info {
            position: relative;
            margin-bottom: 60px;

            @include md {
                position: absolute;
                bottom: 160px;
                margin-bottom: 0px;
            }

            p {
                font-family: $medium !important;
                font-size: 20px !important;
                margin-bottom: 24px;

                a{
                    color: #000000;
                }

                &:last-child {
                    margin-top: 50px !important;
                }
            }
        }
    }

    .col-form {
        @include md {
            padding: 0 0 0 8px !important;
        }
    } 
}

