.circles-background{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &.home-page{
        .container-left{
            top: 86vh;
        }

        .container-right{
            top: 200vh;
        }
    }

    &.request-page {
        .container-left{
            top: 20vh;
        }

        .container-right{
            top: 60vh;
        }
    }

    .container-left {
        position: absolute;
        user-select: none;
        left: -120px;
        top: 55vh;
        
        @include lg {
            left: -40px;
            top: 75vh;
        }

        .circleLeft {
            user-select: none;
        }
    }

    .container-right {
        width: 40px;
        position: absolute;
        user-select: none;
        right: 0px;
        top: 140vh;
        overflow: hidden;

        @include lg {
            width: 70px;
            top: 125vh;
        }
    }

    .circleRight, .circleLeft  {
        user-select: none;
        overflow: hidden;
    }
}
