section.homepage {
    padding: 0px 32px 0px 32px;
    overflow-x: hidden;

    @include lg {
        padding: 0px;
    }

    .circleLeft {
        position: absolute;
        user-select: none;
        left: -140px;
        margin-top: -205px;

        @include lg {
            left: -40px;
            margin-top: -110px; 
        }
    }

    .decoration {
        position: absolute;
        width: 100%;
        overflow: hidden;
        height: 100%;

        .circleRight {
            position: absolute;
            user-select: none;
            right: -70px;
            top: 370px;
            height: 350px;
            width: auto;
        }
    }

    .paragraph {
        display: block;
        margin-top: 24px;
    }

    .first-block {
        position: relative;
        
        .block-photo {
            background: $bg-grey;
            width: 100%;
            height: 236px;
            margin-top: 25px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    
        .button-container {
            position: relative;
            bottom: 25px;

            @include lg {
                position: absolute;
                right: -15px;
                bottom: -13px;    
            }
        }

    }

    .second-block {
        .title {
            min-height: 70px;
        }
    }

    .third-block {
        margin-top: 154px;
        margin-bottom: 100px;

        @include lg {
            margin-bottom: 140px;
        }

        .title {
            color: $paragraph;
            margin-bottom: 35px;
            text-align: center;

            @include lg {
                text-align: left;
            }
        }

        .text {
            font-family: $light;
            font-size: 1.1rem;
            color: $paragraph;
            text-align: center;
            display: block;

            @include lg {
                text-align: left;
            }
        }

        .read-more-button {
            display: block;
            color: $primary;
            font-family: $extrabold;
            font-size: 20px;
            text-decoration: none;
            text-align: center;
            margin-bottom: 45px;

            @include lg {
                margin-bottom: 0;
                text-align: left;
            }

            .arrow {
                height: 15px;
                width: auto;
                margin-left: 11px;
                user-select: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .image-container {
            position: relative;

            .block-image {
                height: 235px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                @include lg {
                    height: 520px;
                    margin-bottom: 25px;
                    margin-right: 25px;
                }
            }

            .button-container {
                position: relative;
                bottom: 25px;

                @include lg {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
            }
        }
    }

    >.container {
        margin-top: 99px;

        @include lg {
            margin-top: 114px;
        }
    }

    .block-image,
    .block-photo {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgb(30, 67, 162);
            background: linear-gradient(0deg, rgba(30, 67, 162, 0.2) 0%, rgba(30, 67, 162, 0.2) 100%);
        }
    }
}