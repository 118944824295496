.icon-container {
    .icon-block {
        height: 168px;
        width: 168px;
        border-radius: 84px;
        background: $primary;
        position: relative;
        user-select: none;

        .icon-1,
        .icon-2,
        .icon-3 {
            width: 90px;
            height: auto;
        }

        .icon-1 {
            transform: translateX(7px);
        }
    
        .icon-2 {
            transform: translateY(-5px);
        }
    }

    .number-container {
        width: 47px;
        height: 47px;
        border-radius: 27px;
        background: $paragraph;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .number {
        position: absolute;
        top: 12px;
        font-family: $semibold!important;
        font-size: 25px!important;
        color: $white;
    }

    .title {
        margin-top: 25px;
        font-family: $extrabold!important;
        font-size: 26px!important;
        line-height: 30px;
        color: $paragraph;
        display: block;
        text-align: center;

        @include lg {
            width: 80%;
        }
    }

    .text {
        // margin-top: 23px;
        font-family: $light!important;
        font-size: 1.1rem!important;
        color: $paragraph;
        display: block;
        text-align: center;
        margin-bottom: 60px;

        @include lg {
            width: 80%;
            margin-bottom: 0;
        }
    }

    &:last-child .text {
        margin-bottom: 0;
    }
}