//Fields
.input-wrapper {
    position: relative;

    label {
        position: absolute;
        top: 18px;
        left: 20px;
        color: $input-label;
        pointer-events: none;
        font-size: 18px;
        font-family: $light;
        transition: .2s;
    }
    
    .input,
    .textarea {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        padding: 20px 20px 5px 20px;
        border-radius: 7px;
        color: $paragraph;
        transition: .1s;
        background: $input-bg-active;
        border: 0;
        font-size: 18px;
        font-family: $medium;

        &:focus {
            outline: 0;
        }
        
        &:not(:placeholder-shown) + label,
        &:focus + label,
        &:-webkit-autofill + label {
            top: 6px;
            left: 20px;
            transition: .2s;
            color: $input-label;
            font-size: 14px;
        }

        &.disabled {
            background: $input-bg-disabled;
        }

        &.extra-padding {
            padding-right: 68px;
        }

        &.border {
            border: 1px solid $input-bg-active !important;
        }
    }

    .input {
        height: 55px;
    }

    .textarea {
        resize: none;
    }

    &.error {
        .input,
        .textarea {
            border: 1px solid $error !important;
        }
    }


    .secure-icon-container {
        position: absolute;
        right: 15px;
        top: 10.5px;
        background: $input-bg-active;
        border-radius: 100%;
        z-index: 2;
        height: 35px;
        width: 35px;
        text-align: center;
        user-select: none;

        &:hover {
            cursor: pointer;
            background: darken($input-bg-active, 10%);
        }

        .secure-icon {
            position: relative;
            top: 6.5px;
            left: 0.5px;
        }
    }
}

.error-text {
    color: $error;
    font-size: 14px;
    position: relative;
    font-family: $medium;
    top: -15px;
}