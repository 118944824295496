.request-header {
    .content {
        padding: 0 32px;

        @include lg {
            padding: 0;
        }

        .title {
            color: $paragraph;
            font-size: 40px;
            margin-bottom: 35px;
            text-align: center;

            @include lg {
                text-align: left;
            }
        }

        .text {
            color: $paragraph;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            display: block;

            @include lg {
                text-align: left;
            }
        }

        .step {
            font-family: $bold;
            font-size: 25px;
            color: $paragraph;
            text-decoration: underline;
            margin-top: 15px;
        }

        .progress-bar {
            margin: 29px 0 50px 0;
            height: 8.86px;
            background: $input-bg-active;
            border-radius: 2px;
    
            @include lg {
                margin: 43px 0 46px 0;
            }
    
            .progress {
                background: $primary;
                transition: .5s;
    
                &._1-4 {
                    width: 25%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &._3-4 {
                    width: 75%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &._1-3 {
                    width: 33.5%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &._2-3 {
                    width: 66.5%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &._1-2 {
                    width: 50%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
    
                &.full {
                    width: 100%;
                }
            }
        }
    }
}