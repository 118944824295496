$white: #FFF;
$black: #000;

$primary: #336BFC;
$secondary: #C292F8;
$gray-buttons: #8A8785;

$heading:  #251f1c;
$paragraph: $heading;

$success: #4affc7;
$error: #ff4d4d;

$bg-grey: #EDEDED;

$input-label: $gray-buttons;
$input-bg-active: #EFEFEF;
$input-bg-disabled: #CCC;
$input-border: #D6D6D6;

$header-border: #5A5A5A;

$checkbox-border: #D6D6D6;