@import '~bootstrap/scss/bootstrap';

@import 'defaults/mixins';
@import 'defaults/colors';
@import 'defaults/fonts';
@import 'defaults/general';

@import '../components/header/header';
@import '../components/header/menu/menu';
@import '../components/header/modal_logout/modal_logout';

@import '../components/footer/footer';
@import '../components/footer/menu/menuFooter';
@import '../components/button/button';
@import '../components/textinput/textinput';
@import '../components/dateinput/dateinput';
@import '../components/loader/loader';
@import '../components/page_banner/page_banner';
@import '../components/subtitle_page/subtitle_page';
@import '../components/bottom_banner/bottom_banner';
@import '../components/circles_background/circles_background';
@import '../components/checkbox/checkbox';
@import '../components/radiobutton/radiobutton';
@import '../components/tooltip/tooltip';

@import '../screens/404/404';
@import '../screens/home/home';
@import '../screens/home/icon_block/icon_block';
@import '../screens/request/request';
@import '../screens/textpage/textpage';

@import '../screens/request/components/header/header';
@import '../screens/request/components/footer/footer';
@import '../screens/request/components/question/question';
@import '../screens/request/components/user_created/user_created';
@import '../screens/request/components/modal_description/modal_description';
@import '../screens/request/components/input_address/input_address';
@import '../screens/request/components/calculate_cost/calculate_cost';
@import '../screens/request/components/account_information_form/account_information_form';
@import '../screens/request/pages/previous_questions/previous_questions';
@import '../screens/request/pages/resident_questions/resident_questions';
@import '../screens/request/pages/price_questions/price_questions';
@import '../screens/request/pages/account_creation/account_creation';
@import '../screens/request/components/error_form/error_form';
@import '../screens/request/components/contact_us/contact_us';

@import '../screens/account/overview';
@import '../screens/account/components/menu/menu';
@import '../screens/account/components/document/document';
@import '../screens/account/components/form_tax/form_tax';
@import '../screens/account/subpages/personal_info/personal_info';
@import '../screens/account/subpages/billing/billing';
@import '../screens/account/components/personal_information_form/personal_information_form';
@import '../screens/account/components/modal_upload/modal_upload';
@import '../screens/account/components/modal_delete/modal_delete';
@import '../screens/account/components/new_form/new_form';

@import '../screens/contact/contact';
@import '../screens/login/login';
@import '../screens/contact/contact_form/contact_form';
@import '../screens/forgotPassword/forgotPassword';

@import '../screens/faq/components/questions/questions';
@import '../screens/faq/faq';

@import '../screens/validate-account/validate-account';
