.user-created {
    padding-bottom: 20vh;

    .description * {
        max-width: 500px;
        margin-bottom: 40px;
        font-size: 30px !important;
        line-height: 42px !important;
    }
}
