.modal-delete {
    display: flex;
    z-index:11;
    width: 100%;
    height: 100vh;
    background-color: #000000bb;
    position: fixed;
    overflow-x: hidden;
    top: -100vh;
    transform: translateY(-100vh);
    left: 0px;
    transition: .4s;

    &:hover {
        cursor: initial !important;
    }

    .modal-dialog {
        max-width: 600px;
        margin: auto;
    }

    &.visible-modal {
        transform: translateY(100vh);
        transition: .4s;
    }

    .modal-body {
        padding: 40px;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;

        h3 {
            text-align: center;
            font-family: $semibold !important;
            margin-bottom: 20px;
        }

        h4 {
            text-align: center;
            font-family: $semibold !important;
            margin-bottom: 16px;
        }

        .modal-footer {
            width: 100%;
            border-top: none;
            padding: 12px 0;
            justify-content: flex-end;
    
            .button {
                width: 100%;
            }
    
            @include sm {
                justify-content: flex-end;
    
                .button {
                    width: auto;
                }
            }
        }
    }
}
