.page-banner {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0px 18px;

    &.full {
        min-height: 570px;
        height: 97vh;

        @include lg {
            min-height: 500px;
        }
    }

    &.mid {
        height: 68vh;
    }

    &.small {
        height: auto;
        margin-top: 70px;
        margin-bottom: 20px;
    }

    .content {
        z-index: 2;
        position: relative;
        height: 100%;
        padding: 0 10px;

        .title {
            font-family: $black !important;
            color: $white;
            display: block;
            text-align: center;
        }

        .title-blue {
            color: $paragraph;
        }

        .subtitle {
            color: $white;
            display: block;
            font-size: 36px;
            margin: 35px 0 27px 0;

            &.small-subtitle{
                font-family: $light !important;
                font-size: 30px !important;
                letter-spacing: 0.07em;
                text-align: center;
                line-height: 1.2;
                margin-top: 10px;
                padding: 0px 20px;
            }

            &.subtitle-front-page {
                margin: 0px;
            }

            &.last-item {
                margin-bottom: 37px;
            }
        }

        .container-button {
            width: 100%;

            .button {
                width: 100%;
    
                @include md {
                    width: auto;
                }
            }
        }

        
    
        .bottom-text {
            position: absolute;
            bottom: 22px;
            color: $white;
            text-align: center;
            padding: 0 10px;
        }
    }

    .overlay {
        position: absolute;
        z-index: 1;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: rgb(30, 67, 162);
        background: linear-gradient(0deg, rgba(30, 67, 162, 0.4) 0%, rgba(30, 67, 162, 0.4) 100%);
    }
}