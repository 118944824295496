.banner-container {
    padding: 0!important;
    position: relative;
    
    @include md {
        margin-bottom: 45px;
    }

    @include lg {
        padding: 15px!important;
    }
    
    .bottom-banner {
        background: $primary;
        padding: 60px;
        border-radius: 0px;
       
        @include lg {
            padding: 75px;
        }

        h3 {
            color: $white;
            margin-bottom: 45px;
            text-align: center;

            @include lg {
                text-align: left;
                margin-bottom: 0;
                margin-right: 80px;
            }
        }

        * {
            z-index: 1;
        }

        @include md {
            border-radius: 15px;
        }

    }

    .image-container {
        z-index: 0;
        position: absolute;
        width: 100%;
        bottom: -30px;
        height: 100%;
        user-select: none;
        pointer-events: none;

        @include md {
            bottom: -20px;
        }

        @include lg {
            width: initial!important;
            right: 70px;
            top: 23px;
        }
    }
}