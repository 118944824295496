header {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-bottom: 85px;
    user-select: none;

    &.float {
        position: absolute;
        margin-bottom: 0;
    }

    .navigation {
        padding: 20px 0;
        border-bottom: 0.5px solid $input-bg-active;
        margin: 0 32px;
        min-width: 200px;

        > div {
            min-height: 44px;
        }

        @include md {
            margin: 0;
        }

        .logo-text {
            color: $paragraph;
            text-decoration: none;
            margin-right: 95px;
            transition: .2s;
            font-family: $semibold;
            font-size: 20px; 
            
            &:hover {
                @include lg {
                    opacity: .7;
                }
            }
        }

        .menu-item-container {
            display: inline-block;
            position: relative;

            .active-indicator {
                position: absolute;
                height: 3px;
                left: 0;
                right: 35px;
                bottom: -34px;
                border-radius: 3px;
                background: $input-bg-active;
            }

            .indicator-my-account {
                right: 0;
            }
        }

        .menu-item {
            color: $paragraph;
            text-decoration: none;
            margin-right: 35px;
            transition: .2s;
            font-family: $semibold;
            font-size: 20px; 

            &:hover {
                opacity: .8;
                transition: .2s;
                cursor: pointer;
            }
        }

        .sign-in-item {
            color: $paragraph;
            text-decoration: none;
            margin-right: 35px;
        }

        .account-icon {
            height: 28px;
            width: auto;
            margin-right: 16px;
        }

        .menu-icon {
            height: 18px;
            width: auto;
        }

        .mobile-item {
            transition: .2s;
            font-family: $semibold;
            font-size: 20px; 

            &:hover {
                @include lg {
                    opacity: .7;
                }
            }
        }
    }

    .navigation-account {
        > div {
            min-height: 44px;
        }
    }

    &.light {
        .logo-text,
        .menu-item,
        .sign-in-item {
            color: $white;
        }
        .active-indicator {
            background: $input-bg-active;
        }
    }
}