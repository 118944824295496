html {
    overflow-y: scroll;
}

html,
body {
    margin: 0 auto;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: $light!important;
    color: $paragraph;
}

.container{
    padding: 0px;

    @include md {
        padding: 0px 36px;
    }
}

.link-router {
    text-decoration: none;
}

h1, .h1, h2, .h2, h3, .h3 {
    display: block;
    color: $heading;
    //padding: 0px 18px;
}

h1,
.h1 {
    font-family: $extrabold!important;
    font-size: 50px!important;

    &.light {
        color: $white;
    }
}

h2,
.h2 {
    font-family: $bold!important;
    font-size: 36px!important;
}

h3,
.h3 {
    font-family: $extrabold!important;
}

p,
.p {
    font-family: $light!important;
    font-size: 1.1rem!important;
    line-height: 1.65em;
    color: $paragraph;
    display: block;
}

.bg-grey {
    background: $bg-grey;
}

.container-loader .btn-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}