.total-amount {
    margin-top: 20px;
    text-align: center;
    font-family: $semibold;
    font-size: 20px;
    line-height: 20px;
    color: $paragraph;

    @include lg {
        text-align: end;
    }
}
