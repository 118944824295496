.subtitle-page {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .h2{
        max-width:  550px;
        font-style: italic;
        letter-spacing: 0.06em;
        font-size: 35px !important;
        line-height: 40px;
        text-align: center;
    }
}