section.request {
    padding: 0px 18px;

    .container-loader-create {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 500px;
    }

    .container-request {
        position: relative;
    }
}
