.input-address {
    input[type='number'] {
        -moz-appearance: textfield;
    }
    
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    input {
        max-width: 50px;
        height: 40px;
        text-align: center;

        &:focus {
            border-color: $heading;
            outline: 0 none;
        }
    }

    button.button-input {
        height: 30px;
        width: 30px;
        border-radius: 15px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $input-border;

        &:hover {
            cursor: pointer;
            background-color: $input-bg-disabled;
        }

        &:active {
            background-color: $primary;

            img {
                filter: invert(100%);
            }
        }

        img {
            max-width: 100%;
        }
    }
}