.error-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .error {
        display: inline-block;
        background: $bg-grey;
        margin-top: 25px;
        margin-bottom: 0px;
        padding: 12px 34px;
        border-radius: 5px;
        text-align: center;
    
        p {
            margin-bottom: 0;
        }

        a {
            color: $black;
        }
    }
}
