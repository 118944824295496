.container.text-page {
    padding: 0px 18px;
    max-width: 720px;
    margin-bottom: 120px;

    &.container-contact {
        margin-top: -90px;
    }
}

.page-with-header {
    margin-top: 50px;
}

.container-loader-text-page .btn-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.erikjan-photo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
        max-width: 250px;
    }
}
