.personal-information-form {
    background: $bg-grey;
    margin-bottom: 25px;
    padding: 41px 34px;
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    .input {
        background-color: $white;
    }

    .title {
        display: block;
        font-family: $bold;
        font-size: 40px;
        color: $paragraph;
        margin-bottom: 40px;
        text-align: center;
    }

    .container-loader-input-personal {
        position: relative;

        .btn-loader {
            z-index: 9;
            position: absolute;
            top: 0;
            left: 48%;
        }
    }

    .container-save {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;

            font-size: 18px;
            font-family: $semibold;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                color: $input-label;
                cursor: inherit;
            }
        }
    }

    .container-message {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }

}
