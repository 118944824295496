.question-container {
    position: relative;
    background: $bg-grey;
    margin-bottom: 25px;
    padding: 41px 34px 18px 34px;
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    .question {
        display: block;
        font-family: $bold;
        font-size: 26px;
        line-height: 26px;
        color: $paragraph;
        margin-bottom: 20px;
        padding-right: 10px;

        .multi-question {
            display: block;
            margin-top: 20px;
            font-size: 18px;
            font-family: $medium;
        }

        &.hide-dividend-tax {
            .dividend-tax {
                display: none;
            }
        }

        &.hide-study-cost {
            .study-cost {
                display: none;
            }
        }

        &.no-fiscal-partner {
            .hide-fiscal-partner {
                display: none;
            }
        }
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .secondary {
        background-color: $input-border;
        color: $paragraph;

        &:hover {
            background-color: $input-bg-disabled;
            color: $paragraph;
        }
    }

    .blue {
        background-color: $primary;
        color: $white;

        &:hover {
            color: $white;
            background-color: lighten($primary, 12%);
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $input-bg-disabled;
        background-image: url("data:image/svg+xml;utf8,<svg fill='1D2B47' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 10px;

        font-size: 18px;
        color: $paragraph;
        text-align: center;
        font-family: $semibold;
        border: none;
        display: inline-block;
        padding: 12px 31px;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            background-color: $input-bg-disabled;
            transition: 0.2s;
        }

        option {
            background-color: $input-bg-active;
            font-family: $semibold;
            font-size: 18px;
            padding-inline: 10px;

            &:hover {
                background-color: darken($input-bg-active, 10%);
            }
        }
    }

    select::-ms-expand {
        display: none;
    }

    .button-question {
        position: absolute;
        top: 40px;
        right: 10px;
        max-width: 25px;
        max-height: 25px;
        transition: 0.2s;

        &:hover {
            cursor: pointer;
            filter: opacity(70%);
            transition: 0.2s;
        }
    }

    .input-co {
        margin-bottom: 30px;

        span {
            text-align: center;
            font-size: 1.2rem;
            font-family: $semibold;
            margin-bottom: 10px;
            display: block;

            @include lg {
                text-align: left;
            }
        }
    }

    .container-question-multiple {
        margin-bottom: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: space-between;

        @include sm {
            flex-direction: row;
        }

        .questions-multiple {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            @include sm {
                justify-content: start;
            }

            .button {
                width: 100%;
            }
        }

        span {
            font-family: $medium;
        }
    }

    textarea {
        background: $white !important;
    }
}
