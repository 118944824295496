.account-information-form {
    background: $bg-grey;
    margin-bottom: 25px;
    padding: 41px 34px;
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    .title {
        display: block;
        font-family: $bold;
        font-size: 26px;
        color: $paragraph;
        margin-bottom: 40px;
    }

    .container-loader-input-account {
        position: relative;

        .btn-loader {
            z-index: 9;
            position: absolute;
            top: 0;
            left: 48%;
        }
    }

    .input {
        background: $white;
    }

    .checkbox-label {
        width: 100%;
    }

    .col-checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
            font-family: $medium;
        }
    }

    .explain-pass {
        display: block;
        margin: -15px 5px 15px 5px;
        font-size: 14px;
    }
}

.country-selector {
    font-family: $medium;
    font-size: 18px;
    color: $paragraph;
    height: 55px;
    margin-bottom: 20px;
    width: 270px;
    border-radius: 5px;
    border-width: 0px;
    padding-left: 16px;
    background: $white
        url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 16px) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;

    option {
        font-family: $medium;
    }

    &:hover {
        cursor: pointer;
    }
}
