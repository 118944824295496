section.account {
    margin: 0 32px 38px 32px;

    @include lg {
        margin: 0 0 38px 0;
    }

    .account-navigation {
        position: relative;

        .nav-block {
            display: block;
            padding: 17px;
            text-align: center;
            text-decoration: none;
            transition: .2s;
            margin-bottom: 19px;
            color: $paragraph;

            @include lg {
                margin-bottom: 0;
            }

            &.active,
            &:hover {
                background: $primary;
                color: $white;
                border-radius: 5px;
                cursor: pointer;
            }

            &.active:hover {
                background: lighten($primary, 12%);
                transition: .2s;
            }
        }
    }
}