.contact-us {
    position: absolute;
    bottom: 20px;
    left: 0px;
    background-color: $primary;
    padding: 10px 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p,
    a {
        margin-bottom: 0;
        color: $white;
    }

    a {
        font-family: $medium;
    }
}
