.faq{
    margin-bottom: 20px;
    padding: 0px 18px;

    @include md {
        margin-bottom: 60px;
    }

    .col-info {
        position: relative;

        @include md {
            padding: 0 8px 0 0 !important;
        }        
    }

    .col-form {
        @include md {
            padding: 0 0 0 8px !important;
        }
    } 
    .container-loader-text-page .btn-loader{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
    }
}

