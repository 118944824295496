.form-tax {
    background-color: $bg-grey;
    border-radius: 8px;
    margin-bottom: 20px;
    padding-bottom: 1px;

    &.form-approved {
        padding-bottom: 20px;

        .content-block-account {
            margin-bottom: 20px;
        }
    }

    .link {
        text-decoration: underline;
        color: $paragraph;
        font-family: $medium;
        font-size: 20px;
    }

    .container-notes {
        textarea {
            background: $white;
        }
    }
}

.status-form {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 30px;
    color: $white;
    font-family: $medium;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &.completed {
        background: $success;
        color: $heading;
    }

    &.in-progress {
        background: $primary;
    }

    &.rejected {
        background: $error;
    }

    &.approved {
        background: $primary;
    }

    &.paid {
        background: $success;
        color: $paragraph;
    }
}

.message-co-owners {
    padding: 15px 25px 0px 25px;
    display: flex;
    justify-content: center;

    @include lg {
        justify-content: left;
    }
}
