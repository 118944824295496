.content-block-account {
    --bs-gutter-x: 0;
    background-color: darken($input-bg-active, 4%);
    border-radius: 5px;
    user-select: none;
    margin: 0px 25px 50px 25px;

    @include lg {
        padding: 24px 25px;
        margin-bottom: 9px;
    }

    > div:first-child {
        padding: 0;

        @include lg {
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
        }
    }

    &:hover {
        cursor: pointer;
    }

    .status {
        position: relative;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 20px;
        padding: 0px 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        .text-right {
            text-align: end;
        }
        
        @include lg {
            border-radius: 0px;
            background: $white;
            width: 42px;
            height: 42px;
            max-width: 42px;
            max-height: 42px;
            border-radius: 21px;
            padding: 0px;

            .text-right {
                text-align: start;
            }
        }

        .image {
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 21px;

            @include lg {
                width: 42px;
                height: 42px;
                max-width: 42px;
                max-height: 42px;
            }

            img {
                max-width: 20px;
                max-height: 20px;
            }
        }

        span {
            color: $white;
            font-family: $semibold;
            font-size: 14px;
            line-height: 17px;
            z-index: 0;
            min-width: 120px;
        }

        &.expanded {
            height: 40px;
            justify-content: space-between;

            @include lg {
                justify-content: center;

                span {
                    position: absolute;
                    width: max-content;
                    transform: rotate(90deg);
                    background-color: red;
                    padding: 12px 15px 12px 35px;
                    top: 60px;
                }
            }
        }

        &.upload {
            background: $primary;

            .image {
                background: $primary;
            }

            span {
                background-color: $primary;

                @include lg {
                    background-color: lighten($primary, 10%);
                }
            }
        }

        &.pending {
            background-color: $primary;

            .image {
                background-color: $primary;
            }

            span {
                background-color: $primary;

                @include lg {
                    background-color: lighten($primary, 10%);
                }
            }
        }

        &.rejected {
            background-color: $error;

            .image {
                background-color: $error;
            }

            span {
                background-color: $error;

                @include lg {
                    background-color: lighten($error, 10%);
                }
            }
        }

        &.success {
            background-color: $success;

            .image {
                background-color: $success;

                img {
                    filter: brightness(25%);
                }
            }

            span {
                color: $heading;
                background-color: $success;

                @include lg {
                    background-color: lighten($success, 10%);
                }
            }
        }

        &.expanded {
            &.completed,
            &.error {
                height: 48px;
                background-position: left 20px center;

                @include lg {
                    height: 120px;
                    background-position: 9px 9px;
                }
            }
        }
    }

    .file-document {
        background-color: $input-border;
        position: relative;
        border-radius: 5px;
        padding: 10px;
        text-transform: capitalize;
        text-align: center;
        font-family: $semibold;
        font-size: 14px;
        color: $white;

        &.extra-padding {
            padding-top: 40px;
        }

        .status-document {
            position: absolute;
            top: 0;
            left: 0;

            &.approved{
                background-color: $success;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &.pending{
                background-color: $primary;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &.rejected{
                background-color: $error;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }
    }

    .banner {
        display: block;
        color: $paragraph;
        font-size: 16px;
        position: absolute;
        top: 12px;
        left: 0px!important;
        right: 20px;
        text-align: right;

        @include lg {
            // height: 30px;
            top: 90px!important;
            left: 0px!important;
            padding: 3px 10px 0px 23px;
            transform: rotate(-90deg);
            text-align: left;
        }

        &.completed {
            color: $heading;
            left: -36px;
        }

        &.error {
            color: $white;
            left: -32px;
        }
    }

    .container-external-arrow {
        .arrow-container {
            position: relative;
            bottom: -15px;
        }
    }

    .container-arrow-expanded {
        .arrow-container {
            position: relative;
        }
    }

    .button {
        background-color: $secondary;

        &:hover {
            background-color: lighten($secondary, 10%);
        }
    }

    .arrow {
        height: 35px;
        width: 35px;
        background: $gray-buttons;
        border-radius: 100%;
        background-image: url('../../../../images/plus.svg');
        background-repeat: no-repeat;
        background-position: center;

        @include lg {
            position: absolute;
            position: block;
            margin-top: -5px;
            margin: 0 auto;
        }

        &:hover {
            background-color: lighten($gray-buttons, 10%);
        }

        &.expanded {
            background-image: url('../../../../images/minus.svg');
        }
    }

    .container-title {
        min-height: 80px;
    }

    .title {
        font-family: $semibold;
        font-size: 16px;
        display: block;
        margin: 23px 20px 5px 20px;
        text-align: center;

        @include lg {
            font-size: 18px;
            margin: 2px 0px 0px;
            text-align: left;
        }
    }

    .text {
        font-family: $light;
        font-size: 16px;
        display: block;
        text-align: center;
        margin: 5px 20px 10px 20px;

        @include lg {
            text-align: left;
            margin: 10px 0 30px 0;
        }

        &.subtitle {
            font-style: italic;
        }
    }

    .link {
        text-decoration: underline;
        color: $paragraph;
        font-family: $light;
    }
}

.completed-block {
    background-color: $bg-grey;
    border-radius: 5px;
    padding: 28px 35px;
    margin-bottom: 25px;
    text-align: center;

    .title {
        font-size: 22px;
        font-family: $semibold;
        display: block;
        margin-bottom: 5px;
        text-align: center;
    }
    .text {
        font-size: 16px;
        font-family: $medium;
        display: block;
    }

    .button-container {
        margin-top: 12px;
        width: 100%;
        user-select: none;
        align-self: center;

        @include lg {
            width: 80%;
        }
    }
}