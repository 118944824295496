.menu-footer{
    h5{
        color: $paragraph;
        font-family: $bold!important;
        font-size: 25px;
        margin-bottom: 30px;
        letter-spacing: 0.06em;
    }

    .menu-item-container{
        color: $paragraph;
        text-decoration: underline;

        .menu-item{
            color: $paragraph;
            font-family: $light!important;
            font-size: 16px !important;
            line-height: 24px;
            color: $black;
            letter-spacing: 0.06em;
        }
    }
}