.btn-loader {
    display: block;
    margin: 12px 0;

    .loader {
        border: 3px solid $primary;
        border-top: 3px solid transparent;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}