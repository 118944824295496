$semibold: 'Raleway SemiBold';
$bold: 'Raleway Bold';
$extrabold: 'Raleway ExtraBold';
$light: 'Raleway Light';
$bolditalic: 'Raleway Bold Italic';
$medium: 'Raleway Medium';
$mediumitalic: 'Raleway Medium Italic';

@font-face {
    font-family: $bold;
    src: 
        url('../../fonts/Raleway-Bold.ttf') format('ttf'),
        url('../../fonts/Raleway-Bold.woff2') format('woff2'),
        url('../../fonts/Raleway-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $extrabold;
    src: 
        url('../../fonts/Raleway-ExtraBold.ttf') format('ttf'),
        url('../../fonts/Raleway-ExtraBold.woff2') format('woff2'),
        url('../../fonts/Raleway-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $bolditalic;
    src: 
        url('../../fonts/Raleway-BoldItalic.ttf') format('ttf'),
        url('../../fonts/Raleway-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Raleway-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $medium;
    src: 
        url('../../fonts/Raleway-Medium.ttf') format('ttf'),
        url('../../fonts/Raleway-Medium.woff2') format('woff2'),
        url('../../fonts/Raleway-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $semibold;
    src: 
        url('../../fonts/Raleway-SemiBold.ttf') format('ttf'),
        url('../../fonts/Raleway-SemiBold.woff2') format('woff2'),
        url('../../fonts/Raleway-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $mediumitalic;
    src: 
        url('../../fonts/Raleway-MediumItalic.ttf') format('ttf'),
        url('../../fonts/Raleway-MediumItalic.woff2') format('woff2'),
        url('../../fonts/Raleway-MediumItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $light;
    src: 
        url('../../fonts/Raleway-Light.ttf') format('ttf'),
        url('../../fonts/Raleway-Light.woff2') format('woff2'),
        url('../../fonts/Raleway-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}