.content-block {
    background-color: $bg-grey;
    border-radius: 5px;
    user-select: none;
    padding: 12px 12px;
    margin-bottom: 50px;
    position: relative;

    @include lg {
        padding: 24px 25px;
        margin-bottom: 19px;
    }

    > div:first-child {
        padding: 0;

        @include lg {
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
        }
    }

    &:hover {
        cursor: pointer;
    }

    .arrow-container-faq {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;

        @include lg {
            bottom: -16px;
        }
    }

    .plus {
        height: 35px;
        width: 35px;
        background: $secondary;
        border-radius: 100%;
        background-image: url('../../../../images/plus.svg');
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
            background-color: lighten($secondary, 2%);
        }

        &.expanded {
            background-image: url('../../../../images/minus.svg');
        }
    }

    .question {
        line-height: 25px;
        font-family: $semibold;
        font-size: 22px;
        display: block;
        margin: 13px 20px;
        text-align: center;
    }

    .text {
        font-family: $light;
        font-size: 1.1rem;
        line-height: 1.65em;
        display: block;
        // text-align: center;
        margin: 10px 20px 30px 20px;

        @include lg {
            margin: 10px 0 30px 0;
        }
    }
}