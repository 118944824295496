.menu {
    z-index: -1;
    background: $white;
    position: absolute;
    top: 0px;
    padding: 85px 10px 20px 10px;
    right: 0px;
    left: 0px;
    transition: .5s;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    box-shadow: 0px 11px 10px -7px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 11px 10px -7px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 11px 10px -7px rgba(0,0,0,0.1);

    &.open {
        pointer-events: initial;
        opacity: 1;
        transition: .4s;
    }

    a {
        display: block;
        padding: 10px 5px 7px 5px;
        color: $paragraph;
        text-decoration: none;

        &:last-child {
            border-bottom: 0;
        }
    }

    .mobile-row {
        margin: 0;
        padding: 5px 20px;

        @include sm {
            margin: 0;
        }
    }

    .mobile-item {
        position: relative;
        display: inline-block;

        .active-indicator-mobile {
            height: 4px;
            background-color: $secondary;
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            z-index: -1;
        }
    }

    &.light {
        background-color: rgba(0, 0, 0, 0.8);;

        .mobile-item {
            color: $white;
        }

        a {
            border-bottom: none;
        }
    }
}

.close-menu-icon {
    height: auto;
    width: 20px;
    margin: 0 4px;
}