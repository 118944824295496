.checkbox-container {
    margin-bottom: 23px;
    width: 100%;

    .checkbox-label {
        display: block;
        font-family: $medium;
        font-size: 16px;
        color: $paragraph;
        width: 70%;
        word-break: break-all;
        position: relative;
        top: 6px;
    }

    .checkbox-input {
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        margin-right: 25px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        background-color: $white;
        border: none;
        box-shadow: none;
        outline: 1.5px solid $checkbox-border;
        transition: 0.1s;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
            position: absolute;
            display: block;
            left: 11px;
            top: 6px;
            width: 9px;
            height: 14px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:checked {
            background-color: $primary;
            outline: 1.5px solid $primary;
            transition: 0.1s;
            background-image: url('../../images/checkmark.png');
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}