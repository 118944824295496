.modal-upload {
    display: flex;
    z-index:11;
    width: 100%;
    height: 100vh;
    background-color: #000000bb;
    position: fixed;
    overflow-x: hidden;
    top: -100vh;
    transform: translateY(-100vh);
    left: 0px;
    transition: .4s;

    &:hover {
        cursor: initial !important;
    }

    .modal-dialog {
        min-width: 90%;
        margin: auto;

        @include md {
            min-width: 600px;
            width: 100%;
        }
    }

    &.visible-modal {
        transform: translateY(100vh);
        transition: .4s;
    }

    .modal-body {
        padding: 40px;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;

        h3, h4 {
            text-align: center;
            font-family: $semibold !important;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 20px;
        }

        .container-input {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            align-items: flex-start;
            gap: 10px;
            margin-bottom: 20px;
            
            @include sm {
                flex-direction: row;
                grid-template-columns: 1fr 1fr;
            }

            .new-form-input {
                grid-column: 1 / 3;
            }

            input[type="file"] {
                display: none;
            }

            span {
                margin-top: 10px;
            }

            select {
                width: 100%;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: transparent;
                background-image: url("data:image/svg+xml;utf8,<svg fill='1D2B47' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 100%;
                background-position-y: 4px;

                option {
                    background-color: $white;
                    font-family: $medium;
                    font-size: 16px;
                    padding-inline: 10px;

                    &:hover {
                        background-color: $white;
                    }
                }
            }
            
            select::-ms-expand {
                display: none;
            }

            .custom-file-upload, select {
                width: 100%;
                font-size: 18px;
                color: $input-label;
                font-family: $medium;
                border: 1px solid $input-border;
                display: inline-block;
                padding: 6px 12px;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    background-color: $bg-grey;
                    transition: 0.2s;
                }
            }

            & .error-input {
                border: 1px solid $error;
                color: $error;

                option {
                    color: $primary;
                }
            }
        }

        .container-note-text {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            div {
                width: 100%;
            }
        }

        .file-selected {
            margin-bottom: 10px;
            align-self: flex-start;

            .button-delete-file {
                background-color: $white;
                border: 0;
                padding: 0;
                margin-left: 7px;
    
                img {
                    transform: scale(1.4);
                }
            }
        }

        .btn-loader {
            margin-bottom: 70px;
        }

        .error-field {
            color: $error;
            align-self: flex-end;
            margin-right: 5px;
        }

        .modal-footer {
            align-self: flex-end;
            border-top: none;
            padding: 12px 0;
            justify-content: center;
            width: 100%;

            div {
                width: 100%;

                .button {
                    width: 100%;
                }
            }
            
            
            @include sm {
                justify-content: flex-end;

                div {
                    width: auto;

                    .button {
                        width: auto;
                    }
                }
            }
        }
    }
}
