.new-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 70px 0px;

    @include xs {
        flex-direction: row;
    }

    .text-new-form {
        text-align: center;
        font-size: 26px !important;
        font-family: $semibold !important;
        color: $paragraph;
        margin: 0px;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}